export enum SvgIconName {
    map = 'map',
    magnifier = 'magnifier',
    chevronLeft = 'chevronLeft',
    chevronRight = 'chevronRight',
    chevronUp = 'chevronUp',
    dashboard = 'dashboard',
    messanger = 'messanger',
    notification = 'notification',
    profile = 'profile',
    hamburger = 'hamburger',
    gps = 'gps',
    gpsBold = 'gpsBold',
    plusInCircle = 'plusInCircle',
    minusInCircle = 'minusInCircle',
    plusOnMap = 'plusOnMap',
    fullScreen = 'fullScreen',
    share = 'share',
    filter = 'filter',
    closeCircle = 'closeCircle',
    checkmark = 'checkmark',
    restart = 'restart',
    closeCircleFilled = 'closeCircleFilled',
    passport = 'passport',
    passportFilled = 'passportFilled',
    streetsMapPoint = 'streetsMapPoint',
    pointOnMap = 'pointOnMap',
    facebookSign = 'facebookSign',
    googleSign = 'googleSign',
    appleSign = 'appleSign',
    eye = 'eye',
    eyeClosed = 'eyeClosed',
    spaceshoutLogo = 'spaceshoutLogo',
    addPicture = 'addPicture',
    titlePlaceholder = 'titlePlaceholder',
    textPlaceholder = 'textPlaceholder',
    galleryPlaceholder = 'galleryPlaceholder',
    descriptionPlaceholder = 'descriptionPlaceholder',
    mapPointWave = 'mapPointWave',
    mapMarker = 'mapMarker',
    pencil = 'pencil',
    favouritePoint = 'favouritePoint',
    addCircle = 'addCircle',
    mapPointRectangle = 'mapPointRectangle',
    mapPointCircle = 'mapPointCircle',
    emptyStar = 'emptyStar',
    emptyHearth = 'emptyHearth',
    emptyHearthBig = 'emptyHearthBig',
    filledHearth = 'filledHearth',
    markerFilledHearth = 'markerFilledHearth',
    chatLine = 'chatLine',
    turnRightSign = 'turnRightSign',
    link = 'link',
    message = 'message',
    arrowRight = 'arrowRight',
    threeDots = 'threeDots',
    threeDotsSmall = 'threeDotsSmall',
    subscribedArea = 'subscribedArea',
    addInCircle = 'addInCircle',
    mapPointRemove = 'mapPointRemove',
    profilePicturePlaceholder = 'profilePicturePlaceholder',
    userHands = 'userHands',
    mailBox = 'mailBox',
    password = 'password',
    trashBinMinimal = 'trashBinMinimal',
    logOut = 'logOut',
    oldPasswordChangePlaceholder = 'oldPasswordChangePlaceholder',
    newPasswordChangePlaceholder = 'newPasswordChangePlaceholder',
    repeatedPasswordChangePlaceholder = 'repeatedPasswordChangePlaceholder',
    settings = 'settings',
    dialog = 'dialog',
    chatSquareLike = 'chatSquareLike',
    danger = 'danger',
    toCollection = 'toCollection',
    collectionsBook = 'collectionsBook',
    exifConfirm = 'exifConfirm',
    gallery = 'gallery',
    loadToGallery = 'loadToGallery',
    navigate = 'navigate',
    bookmarkSelected = 'bookmarkSelected',
    bookmarkUnselected = 'bookmarkUnselected',
    telescope = 'telescope',
    membersSelected = 'membersSelected',
    everyoneSelected = 'everyoneSelected',
    addressOverwrite = 'addressOverwrite'
}
